<template>
  <div class="header-user">
    <Select style="max-width: 250px;" placeholder="请选择公司"
            :value="companyOrgId"
            @on-change="companyChange">
      <Option :key="index" :value="item.companyOrgId" :label="item.companyOrgName"
              v-for="(item, index) in info.companyList" />
    </Select>
    <Dropdown :class="{'i-layout-header-user-mobile': isMobile}"
              :trigger="isMobile ? 'click' : 'hover'"
              @on-click="handleClick" >
      <div class="avatar">
        <Avatar size="small" :src="info.imageUrl" v-if="info.imageUrl" />
        <Icon type="md-contact" class="user_header_icon" size="26" v-if="!info.imageUrl" />
        <span class="i-layout-header-user-name" v-if="!isMobile">{{ info.name }}</span>
      </div>
      <DropdownMenu slot="list">
        <DropdownItem name="system" v-if="['2', '3'].includes(info.userType)">
          <Icon type="ios-repeat" /> 切换到「家」
        </DropdownItem>
        <DropdownItem name="logout" :divided="['2', '3'].includes(info.userType)">
          <Icon type="ios-log-out" /> 退出登录
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>
<script>
  import { find } from 'lodash'
  import { mapState, mapActions } from 'vuex'
  import { jia_uri } from '@/setting'
  import { jumpPlatform } from '@/libs/util'

  export default {
    name: 'iHeaderUser',
    computed: {
      ...mapState('admin/user', ['info']),
      ...mapState('admin/account', ['menuList']),
      ...mapState('admin/layout', ['isMobile', 'logoutConfirm']),
      companyOrgId () {
        return this.info.companyOrgId
      },
    },
    methods: {
      ...mapActions('admin/page', ['closeAll']),
      ...mapActions('admin/account', ['logout', 'companyChangeFn']),
      async companyChange(companyOrgId) {
        const { id: employeeId, selfSupport } = find(this.info.companyList, [
          'companyOrgId',
          companyOrgId,
        ])

        this.$Spin.show({
          render: h => h('div', [
            h('Icon', { props: { type: 'ios-loading', size: 18 } }),
            h('div', '切换中'),
          ])
        })
        
        
        await this.companyChangeFn({
          ...this.info,
          selfSupport,
          employeeId,
          companyId: companyOrgId,
        })

        this.$store.commit('admin/menu/setSider', this.menuList)
        this.$nextTick(() => {
          this.$Spin.hide()
          this.$router.push({
            name: 'redirect',
            params: {
              route: encodeURIComponent(this.$router.resolve({name: 'index'}).route.fullPath.substring(1))
            }
          })
          this.handleReload()
        })
      },
      handleReload() {
        this.$emit('on-reload')
      },
      handleClick(name) {
        if (name === 'setting')
          return this.$router.push({ path: '/setting/account' })
        if (name === 'system')
          return jumpPlatform(this.info, `${jia_uri}/#/redirect/dashboard/console`)
        if (name === 'logout') {
          this.logout({
            confirm: this.logoutConfirm,
            vm: this,
          })
        }
      },
    },
  }
</script>
<style lang="less" scoped>
  .header-user {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .avatar {
    cursor: pointer;
  }
  /deep/ .ivu-select {
    flex: 1;
  }
  /deep/ .ivu-dropdown {
    flex: 1;
    padding: 0 12px;
  }
</style>
